import { sentryException } from './sentry';

const protocols = ['http', 'https'];
const hasProtocol = (str: string) => protocols.some((item) => str.startsWith(`${item}://`));
const hasSlash = (str: string) => str[0] === '/';
const addSlash = (data: string) => (hasProtocol(data) || hasSlash(data) ? data : `/${data}`);
const cleanDoubleSlash = (str: string): string => str.replaceAll(/(?<!:)\/{2,}/g, '/');
export const withSlash = (data: string | Array<string | undefined>): string => {
  let result = '';

  result = Array.isArray(data)
    ? data.reduce<string>((res: string, item) => (item ? res + addSlash(item) : res), '')
    : addSlash(data);

  return cleanDoubleSlash(result);
};

const getParams = (search: string): Record<string, string> => {
  const list: Record<string, string> = {};
  search
    .replace('?', '')
    .split('&')
    .forEach((item) => {
      const [key, value] = item.split('=');
      if (key && value) {
        list[key] = value;
      }
    });
  return list;
};
export const getParamsValueByKey = (url: string, key: string): string | null => {
  let result: string | null = '';
  try {
    const urlObject = new URL(url);

    // urlObject.searchParams.get(key) декодируют '+'
    const params = getParams(urlObject.search);
    if (params[key]) {
      result = decodeURIComponent(params[key]);
    } else if (urlObject.search.includes(key)) {
      result = null;
    }
  } catch {
    result = null;
    sentryException(`Invalid URL: ${url}`, 'warning');
  }
  return result;
};
export const getHashParamValue = (url: string, key: string): string | null => {
  let result: string | null = '';
  try {
    const urlObject = new URL(url);

    const hashParams = getParams(urlObject.hash.replace('#', ''));
    if (hashParams[key]) {
      result = decodeURIComponent(hashParams[key]);
    } else if (urlObject.hash.includes(key)) {
      result = null;
    }
  } catch {
    result = null;
    sentryException(`Invalid URL: ${url}`, 'warning');
  }
  return result;
};
