import Keycloak, { KeycloakProfile } from 'keycloak-js';
import { customize, KeycloakMock } from 'keycloak-js-mock';

import { KEYCLOAK_CLIENT_ID, KEYCLOAK_REALM_NAME } from '../../constants/keycloak';
import { TKProfile } from './keycloak.types';

const profile: Omit<TKProfile, 'exp'> = {
  is_profile_fulfill: true,
  given_name: 'Ann Bystrova',
  family_name: 'Bystrova',
  name: 'Ann',
  locale: 'ru',
  preferred_username: 'Ann',
  email_verified: true,
  email: 'm@mail.ru',
  sub: 'userId123',
  iss: 'KeycloakRealmUrl',
  initials: 'AB',
};

export const keycloakInit = (mocked: boolean): Keycloak => {
  if (mocked) {
    customize.profile({ ...(profile as KeycloakProfile) });
    return new KeycloakMock();
  }

  return new Keycloak({
    url: process.env.REACT_APP_KEYCLOAK_URL as string,
    realm: KEYCLOAK_REALM_NAME,
    clientId: KEYCLOAK_CLIENT_ID,
  });
};
