import { Loader as UiLoader, LoaderSizes } from '@design-system/ui-kit-alpha';
import classNames from 'classnames';

import styles from './Loader.module.scss';

interface ILoaderComponentProps {
  active?: boolean;
}

export const Loader = ({ active = true }: ILoaderComponentProps): JSX.Element => (
  <div
    className={classNames(styles.backdrop, {
      [styles.backdrop_active]: active,
    })}
  >
    <div className={styles.root}>
      {active && <UiLoader size={LoaderSizes.l} inline={false} noMargin={false} id={'m-id-loader'} />}
    </div>
  </div>
);

export default Loader;
