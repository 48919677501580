export type TKProfile = {
  is_profile_fulfill: boolean; // is custom feat
  given_name: string;
  family_name: string;
  name: string;
  locale?: string;
  preferred_username?: string;
  email_verified: boolean;
  exp: number; // token expired
  email?: string;
  sub: string; // user Id
  iss: string; // Keycloak realm url
  phone?: string;
  phone_verified?: boolean;
  initials?: string;
};

export enum EKStatus {
  notReady = 'notReady',
  error = 'error',
  isAuth = 'isAuth',
}

export interface IUseKeycloak {
  kToken: null | string | boolean;
  kProfile: null | TKProfile;
  kLogin: () => void;
  kLogout: (redirectUri?: string) => Promise<void>;
  kCreatePasskey: (redirectUri?: string) => string;
  kRefresh: () => void;
  kStatus: EKStatus;
}
