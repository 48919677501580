import { createContext } from 'react';

import { TKProfile } from '../hooks/keycloak';

export interface IAuthContext {
  logout(redirectUri?: string): Promise<void>;
  profile: TKProfile | null;
  refresh(): void;
  createPasskey(redirectUri: string): void;
}

const AuthContext = createContext<IAuthContext>({
  logout: () =>
    new Promise<void>((res) => {
      res();
    }),
  profile: null,
  refresh: () => {},
  createPasskey: (_redirectUri) => {},
});

export default AuthContext;
