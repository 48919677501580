import 'normalize.css';
import './index.scss';
import '@design-system/ui-kit-alpha/dist/main.css';

import React from 'react';
import { createRoot } from 'react-dom/client';

import App from './App';
import { Init } from './utils/init';

const appVersion = process.env.REACT_APP_VERSION;
Init(appVersion);

const rootElement = document.querySelector('#root') as HTMLElement;
const root = createRoot(rootElement);

root.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>,
);
