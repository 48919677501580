import React from 'react';

import { IAuthContext } from '../context/AuthContext';
import useKeycloak, { EKStatus } from './keycloak';

export enum EAuthStatus {
  notReady = 'notReady',
  error = 'error',
  ready = 'ready',
}
export type TAuthStatus = {
  type: EAuthStatus;
  code: number | null;
};
export interface IAuth {
  logout: IAuthContext['logout'];
  profile: IAuthContext['profile'];
  refresh: IAuthContext['refresh'];
  createPasskey: IAuthContext['createPasskey'];
  status: TAuthStatus;
}

let isLoginInProgress = false;

const useAuth = (): IAuth => {
  const { kLogout, kLogin, kProfile, kRefresh, kCreatePasskey, kStatus } = useKeycloak();

  const [status, setStatus] = React.useState<TAuthStatus>({ type: EAuthStatus.notReady, code: null });

  const logout = React.useCallback(
    async (redirectUri?: string) => {
      setStatus({ type: EAuthStatus.notReady, code: null });
      await kLogout(redirectUri);
    },
    [setStatus, kLogout],
  );

  const createPasskey = React.useCallback(
    (redirectUri?: string) => {
      const redirectUrl = kCreatePasskey(redirectUri);

      if (redirectUrl) {
        window.location.replace(redirectUrl);
      }
    },
    [kCreatePasskey],
  );

  const refresh = React.useCallback(async () => {
    setStatus({ type: EAuthStatus.notReady, code: null });
    await kRefresh();
  }, [kRefresh]);

  React.useEffect(() => {
    if (kStatus === EKStatus.notReady && !isLoginInProgress) {
      kLogin();
      isLoginInProgress = true;
    }
  }, [kLogin, kStatus]);
  React.useEffect(() => {
    if (kStatus === EKStatus.error) {
      setStatus({ type: EAuthStatus.error, code: 403 });
    }
    if (kStatus === EKStatus.isAuth) {
      setStatus({ type: EAuthStatus.ready, code: 200 });
    }
  }, [kStatus]);

  return { logout, profile: kProfile, refresh, createPasskey, status };
};

export default useAuth;
