import { decodeJwt } from 'jose';
import { useTimer } from 'library-react-hooks';
import React from 'react';

import { ModeT, withMockModes } from '../../constants/mode';
import { sentryException } from '../../utils/sentry';
import { keycloakInit } from './keycloak.functions';
import { EKStatus, IUseKeycloak, TKProfile } from './keycloak.types';

const timerName = 'keycloakUpdateToken';

const keycloak = keycloakInit(withMockModes.includes(process.env.REACT_APP_MODE as ModeT));

const useKeycloak = (): IUseKeycloak => {
  const { setTimer } = useTimer();

  const [kToken, setKToken] = React.useState<string | null>(null);
  const [kProfile, setKProfile] = React.useState<null | TKProfile>(null);
  const [kStatus, setKStatus] = React.useState<EKStatus>(EKStatus.notReady);

  React.useEffect(() => {
    if (kStatus === EKStatus.notReady || kStatus === EKStatus.error) {
      setKProfile(null);
      setKToken(null);
    }
  }, [kStatus]);

  const tokenUpdate = React.useCallback(
    (status: boolean) => {
      const token: string | undefined = keycloak.token;
      if (!status || typeof token !== 'string' || !token) {
        setKStatus(EKStatus.error);
        sentryException('Keycloak hook: typeof token is not a string or empty', 'warning');
        return;
      }

      setKToken(token);

      const jwtToken = decodeJwt(token) as TKProfile;
      setKProfile(jwtToken);
      setKStatus(EKStatus.isAuth);

      const time = jwtToken.exp - Math.floor(Date.now() / 1000);
      setTimer(time, {
        name: timerName,
        autoFinish: true,
        callback: () =>
          keycloak
            .updateToken(-1)
            .then((kRefreshed) => {
              // console.log('kRefreshed');
              tokenUpdate(kRefreshed);
            })
            .catch((error_: any) => {
              setKStatus(EKStatus.error);
              console.error(error_?.message || 'Failed to tokenUpdate');
              sentryException('Keycloak hook: failed tokenUpdate', 'warning');
            }),
      });
    },
    [setTimer],
  );

  const kLogin = React.useCallback(async () => {
    await keycloak
      .init({
        pkceMethod: 'S256',
        onLoad: 'login-required',
        checkLoginIframe: false,
      })
      .then(async function (authenticated) {
        tokenUpdate(authenticated);
      })
      .catch((error_: any) => {
        setKStatus(EKStatus.error);
        console.error(error_?.message || 'Failed to initialize in Keycloak');
        sentryException('Keycloak hook: failed to initialize in Keycloak', 'warning');
      });
  }, [tokenUpdate]);
  const kLogout = React.useCallback(async (redirectUri?: string) => {
    keycloak.idToken = '';
    await keycloak.logout({ redirectUri });
    setKStatus(EKStatus.notReady);
  }, []);
  const kRefresh = React.useCallback(async () => {
    setKProfile(null);
    await keycloak
      .updateToken(-1)
      .then((kRefreshed) => {
        tokenUpdate(kRefreshed);
      })
      .catch((error_: any) => {
        setKStatus(EKStatus.error);
        console.error(error_?.message || 'Failed to kRefresh the token, or the session has expired');
        sentryException('Keycloak hook: failed refresh the token, or the session has expired', 'warning');
      });
  }, [tokenUpdate]);
  const kCreatePasskey = React.useCallback((redirectUri?: string) => {
    return keycloak.createLoginUrl({ action: 'webauthn-register', redirectUri });
  }, []);

  return { kToken, kLogout, kLogin, kCreatePasskey, kProfile, kRefresh, kStatus };
};

export default useKeycloak;
