import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';

import { LOCALE_DEFAULT } from '../constants/locale';
import { LocaleE } from '../constants/locale.types';
import { sentryException } from '../utils/sentry';
import enTranslation from './en/translation.json';

i18n.use(initReactI18next).init({
  debug: false,
  fallbackLng: LOCALE_DEFAULT,
  resources: {
    [LocaleE.en]: {
      translation: { ...enTranslation },
    },
  },
  interpolation: {
    escapeValue: false,
  },
  react: {
    bindI18n: 'languageChanged',
    bindI18nStore: '',
    transEmptyNodeValue: '',
    transSupportBasicHtmlNodes: true,
    transKeepBasicHtmlNodesFor: ['br', 'strong', 'i', 'p'],
    useSuspense: true,
  },
  missingInterpolationHandler: (text: string) => {
    sentryException(`I18n interpolation value is undefined: ${text}`, 'warning');
  },
});

export { default } from 'i18next';
