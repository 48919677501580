import { UIKitContextProvider, UIKitThemes } from '@design-system/ui-kit-alpha';
import React from 'react';
import { I18nextProvider } from 'react-i18next';

import styles from './App.module.scss';
import Loader from './components/Loader';
import { POPUP_CONTAINER } from './components/Popup';
import AuthContext from './context/AuthContext';
import useAuth, { EAuthStatus } from './hooks/auth.hook';
import useQueryParams from './hooks/queryParams.hook';
import i18n from './locales';
import Main from './pages/Main';
import { sentryStorage } from './utils/sentry';

const App: React.VFC = () => {
  const { logout, profile, refresh, createPasskey, status } = useAuth();
  const ready = React.useMemo(() => status.type !== EAuthStatus.notReady, [status]);
  const query = useQueryParams(ready);

  React.useEffect(() => {
    sentryStorage.put('status', `type: ${status.type}, code: ${status.code}`);
  }, [status]);

  return (
    <UIKitContextProvider theme={UIKitThemes.light}>
      <I18nextProvider i18n={i18n}>
        <AuthContext.Provider value={{ logout, profile, refresh, createPasskey }}>
          <React.Suspense fallback={<div></div>}>
            {ready && (
              <div className={styles.wrapper}>
                <Main query={query} />
              </div>
            )}
            <Loader active={!ready} />
            <div id={POPUP_CONTAINER} />
          </React.Suspense>
        </AuthContext.Provider>
      </I18nextProvider>
    </UIKitContextProvider>
  );
};

export default App;
