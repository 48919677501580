import classNames from 'classnames';
import { ReactChild } from 'react';

import Header from '../../components/Header';
import styles from './Page.module.scss';

interface IPageProps {
  children: ReactChild;
}

const Page = ({ children }: IPageProps): JSX.Element => (
  <div className={styles.root}>
    <Header />
    <div className={classNames(styles.content, styles.common_margin__m)}>{children}</div>
  </div>
);

export default Page;
