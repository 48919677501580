import React from 'react';

import {
  QUERY_PARAMS_KC_CANCELLED,
  QUERY_PARAMS_KC_PASSKEY,
  QUERY_PARAMS_KC_STATUS,
  QUERY_PARAMS_KC_SUCCESS,
} from '../constants/queryParams';
import { getHashParamValue, getParamsValueByKey, withSlash } from '../utils/url';

type TQueryState = {
  isAfterPasskey: boolean;
  isCancelled: boolean;
  isSuccess: boolean;
};
export type TQueryParam = {
  query: TQueryState;
};

const parseParams = (href: string): TQueryState => {
  const isAfterPasskey = getParamsValueByKey(href, QUERY_PARAMS_KC_PASSKEY);
  const isCancelled = getHashParamValue(href, QUERY_PARAMS_KC_STATUS);
  const isSuccess = getHashParamValue(href, QUERY_PARAMS_KC_STATUS);

  return {
    isAfterPasskey: Boolean(isAfterPasskey || false),
    isCancelled: isCancelled === QUERY_PARAMS_KC_CANCELLED,
    isSuccess: isSuccess === QUERY_PARAMS_KC_SUCCESS,
  };
};

const useQueryParams = (clean: boolean): TQueryState => {
  const state = React.useMemo<TQueryState>(() => parseParams(document.location.href), []);

  React.useEffect(() => {
    if (!clean) return;
    const url = new URL(document.location.href);
    window.history.replaceState(Math.random().toString(), 'title', withSlash([url.origin, url.pathname, url.hash]));
  }, [clean]);

  return state;
};

export default useQueryParams;
