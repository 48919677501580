import React from 'react';

/**
 * The hook allows you to drop content from any React component into the DOM element
 */
const usePortal = (id: string): HTMLDivElement | null => {
  const rootElemRef = React.useRef<HTMLDivElement | null>(null);

  React.useEffect(() => {
    // eslint-disable-next-line unicorn/prefer-query-selector
    const parentElem = document.getElementById(id);

    if (!parentElem) {
      return;
    }

    if (!rootElemRef.current) {
      return;
    }

    parentElem.append(rootElemRef.current as Node);

    return () => {
      rootElemRef.current?.remove();
    };
  }, [id]);

  function getRootElem() {
    if (!rootElemRef.current) {
      rootElemRef.current = document.createElement('div');
    }
    return rootElemRef.current;
  }

  return getRootElem();
};

export default usePortal;
