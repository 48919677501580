import classNames from 'classnames';
import React, { useCallback } from 'react';
import { createPortal } from 'react-dom';

import { useUpdateState } from '../../hooks/helper.hook';
import usePortal from '../../hooks/portal.hook';
import { POPUP_CONTAINER } from './Popup.constants';
import styles from './Popup.module.scss';
import { IPopupProps } from './Popup.types';

const Popup: React.FC<IPopupProps> = ({ open = false, callback, className, closable = true, children }) => {
  const target = usePortal(POPUP_CONTAINER);
  // TODO: попап может уехать наверх без возможности скроллиться
  // const windowRef: RefObject<HTMLDivElement> = useRef(null);

  const [show, setShow] = useUpdateState<boolean>(Boolean(open));
  const onClose = useCallback(() => {
    callback?.();
    setShow(false);
  }, [callback, setShow]);

  // useEffect(() => {
  //   if (windowRef?.current) {
  //     const windowHeight = windowRef.current.clientHeight;
  //     console.log('windowHeight', windowHeight);
  //   }
  // }, [windowRef]);

  const wrapperClass = classNames(styles.wrapper, {
    [styles.wrapper_open]: show,
  });
  const windowClass = classNames(styles.window, className);

  if (!target) return null;
  return createPortal(
    <div className={wrapperClass}>
      <div className={styles.backdrop} onClick={closable ? onClose : () => {}} />
      <div className={windowClass}>{children}</div>
    </div>,
    target,
  );
};

export default Popup;
