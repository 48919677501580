import { ModeT, withMockModes } from '../constants/mode';
import { sentryInit } from './sentry';

export const Init = (appVersion?: string) => {
  window.app = {
    version: appVersion,
  };

  if (!withMockModes.includes(process.env.REACT_APP_MODE as ModeT)) {
    sentryInit(appVersion);
  }
};
