import { Button, ButtonThemes, ButtonTypes, Text, TextAndIconColors, TextVariants } from '@design-system/ui-kit-alpha';
import classNames from 'classnames';
import React from 'react';
import { useTranslation } from 'react-i18next';

import Popup from '../../components/Popup';
import { QUERY_PARAMS_KC_PASSKEY } from '../../constants/queryParams';
import Page from '../../containers/Page';
import AuthContext from '../../context/AuthContext';
import { TQueryParam } from '../../hooks/queryParams.hook';
import { withSlash } from '../../utils/url';
import styles from './Main.module.scss';

const Main: React.VFC<TQueryParam> = ({ query }) => {
  const { t } = useTranslation();
  const { createPasskey } = React.useContext(AuthContext);
  const [isPopupShown, setIsPopupShow] = React.useState<boolean>(false);
  const [showResult, setShowResult] = React.useState<boolean>(true);

  const registerPasskey = React.useCallback(() => {
    createPasskey(withSlash([process.env.REACT_APP_URL, `?${QUERY_PARAMS_KC_PASSKEY}=true`]));
  }, [createPasskey]);

  return (
    <Page>
      <div className={styles.form}>
        <Text variant={TextVariants.header3} color={TextAndIconColors.labelSecondary}>
          {t('page.main.title')}
        </Text>
        <div className={classNames(styles.common_margin__m, styles.page_block, styles.page_block__border)}>
          <div className={styles.page_block}>
            <Text variant={TextVariants.header5} color={TextAndIconColors.labelSecondary}>
              {t('block.passkey.title')}
            </Text>
            <Text
              variant={TextVariants.body2}
              color={TextAndIconColors.labelTertiary}
              className={styles.common_margin__xs}
            >
              {t('block.passkey.description')}
            </Text>
          </div>
          <div className={classNames(styles.common_buttons, styles.common_buttons__narrow)}>
            <Button type={ButtonTypes.button} onClick={registerPasskey} theme={ButtonThemes.interfaceAdPrimary}>
              {t('action.addPasskey')}
            </Button>
            <Button
              type={ButtonTypes.button}
              onClick={() => setIsPopupShow(true)}
              theme={ButtonThemes.interfaceOnsurface}
            >
              {t('action.deletePasskey')}
            </Button>
          </div>
          <Popup open={isPopupShown} callback={() => setIsPopupShow((prev) => !prev)}>
            <Text variant={TextVariants.body2} color={TextAndIconColors.labelTertiary}>
              {t('message.deletePasskey')}
            </Text>
          </Popup>
        </div>
        {query.isAfterPasskey && query.isSuccess && (
          <Popup open={showResult} callback={() => setShowResult((prev) => !prev)}>
            <Text variant={TextVariants.body2} color={TextAndIconColors.labelConfirm}>
              {t('message.addedPasskey')}
            </Text>
          </Popup>
        )}
        {query.isAfterPasskey && query.isCancelled && (
          <Popup open={showResult} callback={() => setShowResult((prev) => !prev)}>
            <Text variant={TextVariants.body2} color={TextAndIconColors.labelError}>
              {t('message.errorPasskey')}
            </Text>
          </Popup>
        )}
      </div>
    </Page>
  );
};

export default Main;
